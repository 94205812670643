//
// This is the primary person edit page layout, containing the title, timeline, infobox, etc.
//
const per_style = 'background: #222; color: #bada55; padding: 5px; border-radius: 5px;';
const tlout = 'background: #222; color: #bada55; padding: 5px; border-radius: 5px;';
const prjout = 'background: #80cbc4; color: purple;';
const debug = false;
import { LitElement, html, css, nothing, render } from 'lit';
import { KalePage } from '../shared-components/page.js';
import { KaleForm } from '../shared-components/form.js';
import { table_icons } from '../components/editor_types.js';
import debounce from '../shared-components/utilities/debounce.js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import objectSupport from 'dayjs/plugin/objectSupport'
dayjs.extend(relativeTime);
dayjs.extend(objectSupport);


window.dayjs = dayjs;

import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-icon-button';
import '@material/mwc-top-app-bar';
import '@material/mwc-radio';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-tab-bar';
import '@material/mwc-tab';


import '../shared-components/form.js';

import * as Comlink from 'comlink';

import '../components/edit-person.js';
import '../components/editors.js';
import '../components/person-infobox.js';
import '../components/timeline.js';
import '../components/change-history.js';

import gql from 'graphql-tag';
import {
	PersonInfo,
	client,
	clear_tags,
	insert_tags,
	upsert_timeline_cache_query_with_person,
	formatQueryError,
	createGUID,
	MutationRedirect,
	EditProjectionInfo,
	get_pension_factors,
	fetch_factor_filenames,
	fetch_tax_factor_by_id
} from '../queries/queries.js';

import XLSX from 'xlsx';
import { xlsx_formatters } from '../components/report-components.js';
import { EventDate } from '../benefits-app/eventdate.js';
import { colors } from '../shared-components/styles.js';


let global_worker = new Worker(new URL('/src/benefits-app/pension-timeline-worker.js', import.meta.url), { type: 'module' });
const ordinal = (n) => {
	let s = ["th", "st", "nd", "rd"];
	let v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * 
 * @param {string} date - Date to be parsed
 * @returns {string} - parsed date
 */
const dateParse = (date) => {
    return new Date(date).toISOString().split('T')["0"]
}

/**
 * @param {string} date - date contribution made
 * @param {number} contribution - ee_pension_contribution
 */
const calcSalaryBasedOnContribution = (date, contribution) => {
    let salary_amt;
    const parsed_date = dateParse(date);

    switch(true){
        case parsed_date <= dateParse("1998-06-30"):
            if(contribution < 90){
                salary_amt = (contribution / .03).toFixed(2);
            } else if (contribution === 90){
                salary_amt = 3000.00;
            } else {
                salary_amt = (((contribution - 90) / .05) + 3000).toFixed(2);
            }
        break;

        case dateParse("1998-07-01") <= parsed_date && parsed_date <= dateParse("2013-12-31"):
            if(contribution < 90){
                salary_amt = (contribution / .03).toFixed(2)
            } else if (contribution === 90){
                salary_amt = 3000.00;
            } else {
                salary_amt = (((contribution - 90) / .04) + 3000).toFixed(2);
            }
        break;

        case dateParse("2014-01-01") <= parsed_date && parsed_date <= dateParse("2014-12-31"):
            salary_amt = (contribution / .05).toFixed(2);
        break;

		//If contribution rate changes after 4/30/2024, then update this
        default: 
            salary_amt = (contribution / .06).toFixed(2);
    }

    return Number(salary_amt);
}


const person_page_style = css`
      
@property --proj-tab-pos {
      syntax: "<length>";
      inherits: true;
      initial-value: 0px;
    }


        :host {


          --top-bar-color: var(--paper-grey-700);
          --top-bar-text: white;
          --backdrop-color: var(--paper-grey-200);

          --proj-tab-pos: 0px;

          transition: --proj-tab-pos 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }



        :host([person-type='deceased']){
          --top-bar-color: var(--deceased-person-color);
          --top-bar-text: white;
        }
        :host([person-type='working_retiree']){
          --top-bar-color: var(--working-retiree-person-color);
          --top-bar-text: var(--paper-grey-200);
        }
        :host([person-type='employee']){
          --top-bar-color: var(--employee-person-color);
          --top-bar-text: white;
        }
        :host([person-type='ineligible']){
          --top-bar-color: var(--ineligible-person-color);
          --top-bar-text: white;
        }
        :host([person-type='ex-employee']){
          --top-bar-color: var(--ex-employee-person-color);
          --top-bar-text: white;
        }
        :host([person-type='errors']){
          --top-bar-color: var(--error-person-color);
          --top-bar-text: white;
        }
        :host([person-type='retiree']){
          --top-bar-color: var(--retiree-person-color);
          --top-bar-text: black;
        }

        :host([projecting]) {
          --top-bar-color: var(--paper-teal-500);
          --top-bar-text: white;
          --top-bar-height: 48px;
          --proj-tab-pos: 48px;
        }
        :host([ptransition="in"]) {
          --proj-tab-pos: 0px;
        }
        :host([ptransition="out"]) {
          --proj-tab-pos: 48px;
        }


        .content-area {
          flex: 1;
          padding: 0;
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: stretch;
        }


        #nonesuch_wrap {
          display: flex;
          flex-flow: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          flex: 1;
        }
        #nonesuch {
          text-align: center;
          font-size: 300%;
          opacity: 0.2;
          font-weight: 100; 
        }


        #left-col {
          flex: 1 1 50%;
          /*position: sticky;*/
          top: 0px;
          max-width: 500px;
          z-index: 1;

          overflow: visible;
          /*transform: translateX(calc(-100% - 50px));
          transform-origin: top;
          transition: var(--transform-transition);*/
          margin-left: 16px;
        }
        #left-col[dataloaded] {
          transform: translateX(0);
        }

        #main-column {
          margin-top: 0;
          margin-left: 12px;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
        }
        #main-column > * { width: 100%; height: 100%;}
        div.card-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row;
        }
        div#form {
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: left;
          flex-direction: row;
        }
        div#form > * {
          padding: 0 4px;
        }
        break {
          flex-basis: 100%;
          width: 0px; 
          height: 0px; 
          overflow: hidden;
        }
        table { border-collapse: collapse; }
        td,th { padding: 0.75em; border: none; }
        th { text-align: left; text-transform: uppercase;}
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}

        .pink {
          --mdc-theme-on-primary: white;
          --mdc-theme-primary: #e9437a;
          --mdc-theme-on-secondary: white;
          --mdc-theme-secondary: #e9437a;

        }

        .person_name {
          font-size: 50px;
          white-space: nowrap;
          transition: font-size 0.4s, margin 0.4s;
          transition-timing-function: cubic-bezier(.4, 0, .2, 1);
          margin: 0;
        }
        .person_name.stuck {
          width: 300px;
          max-width: 300px;
          font-size: 30px;
        }

        person-infobox{
          width: 100%;
        }
        person-infobox.stuck {
          }


        mwc-fab {
          position: relative;
          left: 60px;
        }

        .view {
          margin: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        mwc-top-app-bar-fixed {
          background-color: var(--backdrop-color);
          transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }
        mwc-top-app-bar-fixed > * {
          color: var(--app-bar-text);
        }
        mwc-top-app-bar-fixed#second_bar {
          --mdc-theme-primary: var(--paper-grey-200);
          --mdc-theme-on-primary: var(--paper-grey-800);
        }

       
        
        @media screen and (max-width: 600px) { 
          
        }

        mwc-tab-bar {
          background-color: var(--paper-teal-200);
          --mdc-theme-primary: var(--paper-purple-800); /* active text color */
          --mdc-theme-secondary: var(--paper-purple-800); /* indicator icon color */
          margin-right: 48px;
          overflow-x: overlay;
        }

        .tab-bar-back {
          background-color: var(--paper-teal-200);
          box-shadow: var(--mdc-top-app-bar-fixed-box-shadow, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
          position: fixed;
          height: 48px;
          z-index: 3;
          width: 100%;
          max-width: 100vw;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          --mdc-theme-primary: var(--paper-purple-800); /* active text color */
          --mdc-theme-secondary: var(--paper-purple-800); /* indicator icon color */
        }
        .tab-bar-back > mwc-icon-button {
          color: var(--paper-purple-700);
        }
        .tab-bar-back .projection-close {
          margin-left: auto;
        }
        div.tab-bar {
          transform: translateY( calc(-48px + var(--proj-tab-pos) ) );
          transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
          height: 48px;
          width: 100%;
        }

        /*
        .tab-bar[projection] {
          transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
          transform: translateY(0px);
          transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }*/


        .tab-bar-pad {
          height: 48px;
          width: 100%;
        }
        .tab-icon > mwc-icon-button {
          --mdc-icon-button-size: 10px;
        }

       
        .shadowed {
          box-shadow: var(--mdc-top-app-bar-fixed-box-shadow, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
        }

        #projection-chip {
          display: inline-block;
          color: var(--paper-teal-600);
          background-color: white;
          border-radius: 5px;
          margin-left: 12px;
          font-size: 90%;
          font-weight: 100;
          padding-left: 12px;
          padding-right: 12px;
        }
`;
/*
 
@media screen and (max-width: 1200px) { 
	.top-app-bar-adjust {
		margin-right: 0;
	}
	.scroller {
		margin-right: 0;
	}
	.content-area {
		padding: 0;
		flex-flow: column;
		overflow: hidden;
		width: 100%;
	}
	.content-area #left-col {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
	.content-area #main-column {
		width: 100%;
		max-width: 100%;
		margin: 0;
		display: none;
	}

<mwc-icon-button title="Download" icon="file_download" slot="actionItems"></mwc-icon-button>
<mwc-icon-button title="Print" icon="print" slot="actionItems"></mwc-icon-button>
<mwc-icon-button title="Share Record" icon="share" slot="actionItems"></mwc-icon-button>
}*/
class PersonPage extends KalePage {

	static styles = [super.styles, person_page_style]
	static icon = "person"
	static default_title = "View Person"


	constructor() {
		super()
		this.active_tax_factors = null;
		this.bookmarked = false;
		this.exists = true;
		this._projections = [];
		this.subscription = new PersonInfo(
			p => {
				this.person = p ? { ...p } : null;
				this.exists = p ? true : false;
				let bookmarks = p && p.bookmarks ? p.bookmarks.filter(b => b.user_email === window.authmgr.current_login.email) : [];
				this.bookmarked = bookmarks.length > 0;
			},
			{ PersonId: null }
		)
		this.timeline_debounce = debounce((p, proj, r) => this.timeline_actual(p, proj, r), 150);
		this.worker = Comlink.wrap(global_worker);
	}
	static get properties() {
		return {
			person: { type: Object },
			bookmarked: { type: Boolean },
			projecting: { type: Boolean, reflect: true },
			ptransition: { type: String, reflect: true },
			projection: { type: Object },
			mutation_redirect: { type: Object },
			show_contrib_report: { type: Boolean },
			active_user: { type: String },
			personid: { type: Number },
			active_employment: { type: Number },
			title: { type: String },
			person_type: { type: String, reflect: true, attribute: 'person-type' },
			show_projection_report: { type: Boolean },
			calculating: { type: Boolean }

			//events: { type: Array },
			//intervals: { type: Array },
		}
	}
	set simple_model(m) {
		this._model = m;
		if (m?.compute_time > 0) console.log(`%c${this.constructor.name}: recvd model results:`, per_style, m);
		this.person_type = m && m.state ? m?.state?.status_info?.person_type : null;
		this.requestUpdate("simple_model");
	}
	get simple_model() {
		return this._model;
	}

	handleActiveEmploymentChange(e) {
		//FIXME: defunct?
		this.active_employment = e.detail.id
	}

	firstUpdated() {
		super.firstUpdated();
		this.timeline_elem = this.renderRoot.getElementById('timeline');
		this.calculating = true;
		/*
		const bar = this.renderRoot.querySelector("mwc-top-app-bar")
		bar.scrollTarget = bar.nextElementSibling;
		this.scroller = bar.scrollTarget;
		*/

		/*
		this.observer = new IntersectionObserver(e => {
			const stickies = this.renderRoot.querySelectorAll(".sticky");
			const sticking = !e[0].isIntersecting; // true when header is sticky.
			stickies.forEach(s => s.classList.toggle('stuck', sticking)); 
		});
		this.observer.observe(this.renderRoot.getElementById("observer"));
		*/
	}

	/*

			<mwc-top-app-bar .type=${"fixed"} style="z-index: 2;" person-type=${simple_model && simple_model.state ? simple_model.state.status_info.person_type : null} >
				<a href="/" slot="navigationIcon"><mwc-icon-button title="Home" icon="arrow_back" ></mwc-icon-button></a>
				<div slot="title" id="title">${this.title}</div>
				<mwc-icon-button title="Contribution and Interest Report" icon="attach_money" slot="actionItems" @click=${e => this.contributionReport()}></mwc-icon-button>
				<mwc-icon-button title="Bookmark" icon=${ bookmarked ? "bookmark" : "bookmark_border"} slot="actionItems" @click=${e => this.toggleBookmarked()}></mwc-icon-button>
				<a href="/prefs" slot="actionItems"><mwc-icon-button title="Account Prefs" icon="account_circle"></mwc-icon-button></a>
			</mwc-top-app-bar>


	*/
	actions = [
		{
			name: 'Projections',
			icon: 'trending_up',
			action: () => this.toggleProjection(),
			toggled: this.projecting
		},
		{
			name: 'Contribution Report',
			icon: 'account_balance',
			action: () => this.contributionReport()
		},
		{
			name: 'Bookmark',
			icon: () => this.bookmarked ? "bookmark" : "bookmark_border",
			action: () => this.toggleBookmarked()
		},
		...this.actions];

	set projections(p) {
		//   let proj = this.projection;
		//  let q = this._projections;
		//   let j = q && q.length > 0 ? q.findIndex((a,i) => a.id === proj?.id || i === proj?.index) : -1; 
		this._projections = p;
		this.requestUpdate('projections');
		this.findProjectionTab();
		// let i = p && p.length > 0 ? p.findIndex((a,i) => a.id === proj?.id || i === proj?.index) : -1; 
		//  i = i === -1 && p && p.length > 0 ? 0 : i;
		// this.projection = i !== -1 ? {index: i, id: p[i]?.id, data: p[i]} : null;

		/*
		i !== null ? ({
			index: i,
			id: p[i].id,
			data: p[i]
		}) : null;
		*/
	}
	get projections() {
		return this._projections;
	}

	queueProjectionTimeLine() {
		this.timeline_debounce({
			...this.person,
			projections: this.projection
				? [...this.projections.filter(q => q.id !== this.projection.id), this.projection.data]
				: [...this.projections],
			timeline_cache: null
		},
			this.projecting && this.projection ? this.projection.data : null,
			'projection change');
	}

	set projection(p) {
		this._projection = p;
		this._rendered_projection = null;
		//this.person = {...this.person, projections: [...this.projections.filter(q => q.id !== p.id), p.data]};
		this.requestUpdate('projection');
		if (this.projecting) {
			this.queueProjectionTimeLine();
		}
		//console.log("PROJECTION => ", p)
	}

	get projection() {
		return this._projection;
	}

	createProjection() {
		const alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

		let used_letters = new Set(this.projections
			.map(p => p.name?.split?.(' ')?.pop?.()?.split?.('')?.pop()?.toUpperCase?.())
			?.filter?.(c => c.match(/[A-Z]/)));
		//.sort((a,b) => b.charCodeAt(0) - a.charCodeAt(0));
		let next_letter;
		if (used_letters.size < 26) {
			next_letter = alpha.find(a => !used_letters.has(a));
		} else {
			next_letter = `X${[...Array(2)].map(() => alpha[Math.floor(Math.random() * Math.floor(25))]).join('')}`;
		}
		return {
			person_id: this?.person?.id,
			name: `Scenario ${next_letter}`,
			target_date: dayjs().add(5, 'year').format('YYYY-MM-DD'),
			temp_id: createGUID(),
			assumptions: {
				end_condition: {
					type: 'ret',
					years: 10,
					age: 65,
					ret: 'full',
					date: dayjs().add(10, 'year').format('YYYY-MM-DD')
				},
				salary: this.person?.salary_history?.length ?
					{
						type: 'history',
						pct: 2,
						manual: this.person?.contributions?.length && this.person?.contributions?.[this.person?.contributions?.length - 1]?.eoy_base_salary,
					}
					: {
						type: 'history',
						pct: 2,
						salaries: this.person?.contributions?.sort((a, b) => {
							return new Date(a.contribution_date).getTime() - new Date(b.contribution_date).getTime()
						}).filter(d => d.eoy_base_salary !== 0).map(d => {
							let salary = calcSalaryBasedOnContribution(d.contribution_date, d.ee_pension_contribution)
							return {
								salary_amt: salary,
								effective_date: `${new Date(d.contribution_date).getFullYear()}-01-01`
							}
						}).slice(-5),
						manual: this.person?.contributions?.length && (this.person?.contributions?.[this.person?.contributions?.length - 1]?.ee_pension_contribution/.06)	
					},
				beneficiary: {
					dob: this.person.beneficiaries?.filter?.(
						b => !b.removal_date
					)
						//?.sort(
						//  //TODO: get most recent
						//)
						?.[0] ?? this.person.birth_date
				},
				contributions_est: {
					est: 0
				}
			},
			changes: {}
				
		};
	
	}
	mergeProjection(m) {
		console.debug("MERGING P", m, { ...this.projections.map(p => ({ ...p })) });
		let c = this.projections.findIndex(p => p.id === m.id || (p.temp_id && m.temp_id && p.temp_id === m.temp_id));
		if (c > -1) {
			console.debug("FOUND C", c);
			this.projections[c] = { ...m, temp_id: this.projections[c].temp_id };
		} else {
			console.debug("C NOT FOUND", c)
			this.projections = [...this.projections, m];
		}
		this.projections = [...this.projections];
		this.findProjectionTab();
		console.debug("MERGED!", this.projections);
	}

	mergeProjections(merge) {
		//console.log("MERGING", merge, "INTO", this.projections);
		if (this.projections.length === 0) {
			this.projections = [...merge];
		} else {
			let ids = new Set(merge.map(m => m.id));
			this.projections = [...this.projections.filter(p => (p.temp_id && p.id === undefined) || ids.has(p.id))];
			//console.log("FILTERED", this.projections);
			merge.forEach(m => {
				//console.log("MERGING", m);
				let c = this.projections.findIndex(p => p.id === m.id || (p.temp_id && m.temp_id && p.temp_id === m.temp_id));
				if (c > -1) {
					this.projections[c] = { ...m, temp_id: this.projections[c].temp_id };
				} else {
					this.projections = [...this.projections, m];
				}
			});
		}
		this.findProjectionTab();
		//console.log("RESULT", this.projections);
	}

	addProjection() {
		this.projections = [...this.projections, this.createProjection()];
		this.setProjectionIndex(this.projections.length - 1);
	}

	removeProjection(p) {
		this.projections = [...this.projections.filter(q => !((q.id && p.id && q.id === p.id )|| (q.temp_id && p.temp_id && q.temp_id === p.temp_id )))];
		this.findProjectionTab();
	}

	findProjectionTab() {
		let { index, id, temp_id } = this.projection ?? {
			index: 0,
			id: this.projections?.[0]?.id,
			temp_id: this.projections?.[0]?.temp_id
		};
		//console.warn("find proj tab", index, id, temp_id, {...this.projections.map(p => ({...p}))});
		let existing = this.projections?.findIndex(p =>( p?.id && id && p?.id === id) || (p?.temp_id && temp_id && p?.temp_id === temp_id));
		if (existing >= 0) {
			index = existing;
		} else {
			index = index > (this.projections?.length - 1) ? this.projections.length - 1 : index;
		}
		this.setProjectionIndex(index);
	}
	setProjectionIndex(index) {
		this.projection = index >= 0
			? {
				index: index,
				temp_id: this.projections?.[index]?.temp_id,
				id: this.projections?.[index]?.id,
				data: this.projections?.[index]
			}
			: null;
	}

	nextProjection() {
		let i = this?.projection?.index < this.projections.length - 1 ? this?.projection?.index + 1 : 0;
		this.setProjectionIndex(i);
	}
	prevProjection() {
		let i = this?.projection?.index > 1 ? this?.projection?.index - 1 : this?.projections.length - 1;
		this.setProjectionIndex(i);
	}


	getProjection() {
		console.debug("getting a projection", this.projections, this.person?.projections)
		if (!this.projections || this?.projections?.length === 0) {
			this.projections = [this.createProjection()];
		}
		let p = this?.projections[0];
		return {
			index: 0,
			id: p?.id,
			data: p
		}
	}

	toggleProjection() {
		this.projecting = !this.projecting;
		this.queueProjectionTimeLine();
		this.projection = this.projection ? this.projection : this.getProjection();
	}
	/*
	async toggleProjection() {
		this.ptransition = this.projecting ? "out" : "in";
		if (this.ptransition === 'out') {
			let elem = this.renderRoot.querySelector('.tab-bar');
			console.log("out => ", elem);
			await transitionEnd(elem, 500);
			await this.updateComplete;
			this.ptransition = null;
		}
	  
		this.projecting = !this.projecting;
		this.queueProjectionTimeLine();
		this.projection = this.projection ? this.projection : this.getProjection();

		if (this.ptransition === 'in') {
			await this.updateComplete;
			this.ptransition = null;
		}
	}*/

	get top_content_height() {
		return css`calc(${super.top_content_height} + ${(this.projecting ? css`48px` : css`0px`)})`;
	}

	renderTitle() {
		return html`
      <span id="main_title">${this.title}</span>
        ${this.projecting && this.simple_model?.endpoint
				? html`<span id="projection-chip">&hellip;${dayjs().to(dayjs(this.simple_model?.endpoint))}</span>`
				: nothing}`
	}


	renderTopContent() {
		// return this.projecting ? html`${this.projectionEditor(false)}` : nothing;
		/*
 
				 <div slot="icon" class="tab-icon">
				 <mwc-icon-button 
				 icon="close"></mwc-icon-button>
				 </div>
		*/
		return this.projecting
			? html`<div class="tab-bar" ?projection=${this.projecting} ?transition=${this.ptransition}>
      <div class="tab-bar-back">
        <mwc-tab-bar .activeIndex=${this.projection?.index} @MDCTabBar:activated=${e => this.setProjectionIndex(e.detail.index)}>
          ${this.projections.map((p, i) => html`
            <mwc-tab label=${p?.name} > </mwc-tab>
          `)}
        </mwc-tab-bar>
        <mwc-icon-button icon="add_circle" @click=${e => this.addProjection()}></mwc-icon-button>
        <mwc-button class="projection-close" icon="cancel" @click=${e => this.toggleProjection()}>close projection</mwc-button>
      </div>
    </div>
    ` : nothing;
	}

	projectionEditor(long) {
		return html`
    <projection-editor
      ?long=${long}
      .projection=${this.projection?.data}
      .result=${this.simple_model?.future_state}
      @next=${e => this.nextProjection()}
      @previous=${e => this.prevProjection()}
      @add=${e => this.addProjection()}
      @saved=${e => this.mergeProjection(e.detail)}
      @changed=${e => this.mergeProjection(e.detail)}
      @deleted=${e => this.removeProjection(e.detail)}
      @mutation-redirect=${e => {
				this.mutation_redirect = e.detail;
			}}
      @projection-changed=${e => this.projection = {
				...this.projection,
				id: e.detail.id,
				data: e.detail
			}}
      @projection-report=${e => {
				console.log(`%c${this.constructor.name}:`, per_style, "showing report for", this.simple_model?.future_state?.ret_estimates?.target);
				this.show_projection_report = true
			}}
    ></projection-editor>
    `

	}

	/*
	*/

	/*        
								<div class="change_item">
									<div class="change_line1">
										<span class="change_table">${c.user} ${c.verb} ${c.label}</span>
									</div>
								</div>
									<mwc-icon slot="graphic">${c.icon}</mwc-icon>
									<mwc-icon slot="meta">${c.icon}</mwc-icon>
								*/
	renderPage() {
		const { create, simple_model, person, exists, timeline_elem, personid, scroller, bookmarked } = this;
		return html`
        <div class="content-area">
          ${exists ? html`
          <div id="left-col" ?dataloaded=${person && simple_model}>
            <!-- change history -->
            ${this.projecting ? nothing : html`<change-history .changes=${this.changes}></change-history>`}

            <!-- projection info -->
            ${!this.projecting ? nothing : html`${this.projectionEditor(true)}`}
    
            <!-- main person box -->
            <div id="info-box">
              <person-infobox
                @event-jump=${e => timeline_elem.jumpTo(e.detail.event_ids)}
                @warning-highlight=${e => timeline_elem.warning_highlight(e.detail.uuid)}
                @event-highlight=${e => { /*console.warn("highlighting", e);*/ timeline_elem.highlight(e.detail.event_ids); }}
                @event-unhighlight=${e => timeline_elem.unhighlight(e.detail.event_ids)}
                .personid=${personid}
                .person=${person}
                .model=${simple_model}
                .projection=${this.projecting ? this.projection : null}
                .calculating=${this.calculating}
                  ></person-infobox>
            </div>
          </div>
          <div id="main-column" ?dataloaded=${person && simple_model}>
            <new-timeline 
              id="timeline"
              .personid=${personid} 
              .model=${simple_model}
              .projection=${this.projecting ? this.projection : null}
              .calculating=${this.calculating}
              .mutation_redirect=${this.projecting && this.mutation_redirect ? this.mutation_redirect : null}
              @zoomscroll=${e => { scroller.scrollTop += e.detail.incr }}
            ></new-timeline>
          </div>
          ` : html`
          <div id="nonesuch_wrap" class="scroller">
            <div id="nonesuch">NO SUCH PERSON</div>
          </div>
          `}
     
        </div>
        ${this.show_contrib_report ? html`
        <contribution-report .person=${person} .model=${simple_model} .open=${this.show_contrib_report} @closed=${e => this.show_contrib_report = false}></contribution-report>
        ` : ''}
        ${this.show_projection_report ? html`
          <projection-report
            .projection=${this.simple_model?.future_state}
            .person=${this.person}
            .open=${this.show_projection_report}
             @closed=${e => this.show_projection_report = false}
          ></projection-report>` : nothing}
    `
	}

	toggleBookmarked() {
		//TODO: attach user_id to bookmarks
		if (this.bookmarked) {
			client.mutate({
				mutation: gql`
                mutation clear_bookmark($UserEmail: String!, $PersonId: uuid!) {
                  delete_person_bookmark ( where: {_and: [{person_id: {_eq: $PersonId}}, {user_email: {_eq: $UserEmail}}]}) {
                    affected_rows
                  }
                }`,
				variables: { PersonId: this.personid, UserEmail: window.authmgr.current_login.email },
			}).then(data => {
				this.bookmarked = false;
				console.log("bookmark removed", data);
			})
				.catch(error => formatQueryError(error));
		} else {
			client.mutate({
				mutation: gql`
            mutation insert_bookmark($changeMap: person_bookmark_insert_input!) {
              insert_person_bookmark (
                objects: [
                  $changeMap
                ],
                on_conflict: {
                  constraint: person_bookmark_pkey,
                  update_columns: []
                }
              ) {
                returning {
                  person_id
                  user_email
                }
              }
            }
          `,
				variables: { changeMap: { person_id: this.personid, user_email: window.authmgr.current_login.email } },
			}).then(data => {
				this.bookmarked = true;
				console.log("bookmark inserted");
			})
				.catch(error => formatQueryError(error));
		}
	}
	update_timeline_cache({ state, next_year, report, errors, nodes, years, expires }) {
		this.updating_cache = true;
		console.log(`%c${this.constructor.name}:`, per_style, "cache expires", expires);
		nodes = nodes.map(n => ({ ...n, edit_data: n.edit_data ? true : n.edit_data, edit_series_data: n.edit_series_data ? true : n.edit_series_data }));
		//console.log(JSON.stringify(nodes));

		let now = new Date();
		expires = expires ? expires : new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
		let changes = { person_id: this.personid, state: state, report: report, next_year: next_year, errors: errors, nodes: nodes, years: years, expires_date: expires, computed_date: now }
		//if (cache_id) changes.id = cache_id;
		//mutation upsert_timeline_cache($changeMap: timeline_cache_insert_input!, $changeCols: [timeline_cache_update_column!]!) {
		//console.log(JSON.stringify(nodes).length);
		client.mutate({
			mutation: upsert_timeline_cache_query_with_person,
			variables: { changeMap: changes, changeCols: ['computed_date', 'expires_date', 'state', 'next_year', 'report', 'errors', 'nodes', 'years'] },
			// Tags: to_add.map(t => ({ person_id: this.personid, person_tag_type_code: t })) },
			//refetchQueries: ['person_ext_info']
		}).then(async data => {
			this.updating_cache = false;
			console.log("got upsert timeline data");
			const new_person = data && data.data && data.data.timeline_cache && data.data.timeline_cache.returning ? data.data.timeline_cache.returning.person : null;
			if (new_person) this.person = new_person;
		})
			.catch(error => {
				this.updating_cache = false;
				formatQueryError(error);
			})
	}

	async timeline_actual(person, projection, reason) {
		console.log(`%c${this.constructor.name} -> running model`, per_style, person, projection);
		this.ready = true;
		this.calculating = true;

		this.benchmark_start = performance.now();
		let build = Number(window.localStorage.getItem('benefits_build_number'));
		const factors = await get_pension_factors();
		let runner = await (new this.worker(person, projection, build, {...factors, ...(this.active_tax_factors ?? {})}, true));

		if (this.completed_once) {
			await runner.run(null, null, null);
			let final = await runner.result();
			this.simple_model = { ...final, input: { person, projection } };
			this.calculating = false;
			console.log(`%c${this.constructor.name} -> one-shot model: ${(performance.now() - this.benchmark_start).toLocaleString()}ms internal: ${final.compute_time.toLocaleString()}ms`, per_style);
			//console.log('%c${this.constructor.name} -> MODELED', 'background: #222; color: #bada55; padding: 5px; border-radius: 5px;', this.simple_model);
			if (!projection) this.update_timeline_cache(this.simple_model);
			this.requestUpdate('simple_model');
			//FIXME: how to release
			//final.releaseProxy();
		} else {
			console.log("running incremental");
			this.last_render = 0;
			let p1 = Comlink.proxy(() => {
				if (this.ready && performance.now() - this.last_render >= 50) {  // no updates in progress, and throttle to 10fps
					this.ready = false;
					return true;
				}
				return false;
			});

			let p2 = Comlink.proxy(async (intermediate) => {
				this.simple_model = intermediate;
				this.simple_model.in_progress = true;
				this.requestUpdate('simple_model');
				await this.updateComplete;
				this.ready = true;
				this.last_render = performance.now();
			});
			let p3 = Comlink.proxy(async final => {
				this.simple_model = { ...final, input: { person, projection } };
				this.completed_once = true;
				this.calculating = false;
				this.simple_model.in_progress = false;
				console.log(`PERSON -> incremental model: ${(performance.now() - this.benchmark_start).toLocaleString()}ms internal: ${final.compute_time.toLocaleString()}ms`);
				if (!projection) this.update_timeline_cache(this.simple_model);
				this.requestUpdate('simple_model');
			});
			runner.run(p1, p2, p3);
		}

	}


	set search({ person = null }) {
		if (person === null) return;
		const is_uuid = /^[a-zA-Z0-9-]{36}$/;
		if (this.personid !== person) {
			this.personid = person ? person : undefined;
			this.exists = this.personid && is_uuid.test(this.personid);
			//console.log(`person.js: personid=${this.personid} => ${person} (${this.exists ? 'is uuid' : 'non uuid'})`);
			this.requestUpdate('exists');
			if (this.exists) {
				this.subscription.query({ PersonId: this.personid });
				window.person_id = this.personid; // FIXME: HACKY
			}
		}
	}

	called_method() {
		return (new Error())?.stack?.split?.('\n')?.[2]?.trim?.()?.split?.("PersonPage")?.[1]?.split?.('[')?.[0]?.trim?.();
	}

	set person(p) {
		console.log(`%c${this.constructor.name} ${this._person ? this._person.id : null} -> ${(p && this._person && p.id === this._person.id) ? '(no change)' : (p ? p.id : null)}`, 'background: #222; color: #bada55; padding: 5px; border-radius: 5px;');

		// if person changed, or we're live rendering the projection:
		if (p && p !== this._person && (!this._person || this._person.updated < p.updated || (this.projecting && this._rendered_projection !== this._projection))) {

			this._person = p;
			this._rendered_projection = this._projection;
			this.mergeProjections(p?.projections ?? []);
			// this._person_json = truncated_json;
			this.title = this.person && this.exists ? [p.first_name, p.middle_name, p.last_name, p.suffix ? p.suffix.name : null].filter(x => x && x != "").join(" ") : this.exists ? "LOADING" : "NO SUCH PERSON";
			this.dispatchEvent(new CustomEvent('pagetitle', { bubbles: true, composed: true, detail: { title: this.title } }));
			this.requestUpdate('person');
		} else { // just set p
			this._person = p;
			this.requestUpdate('person');
			return
		}

		if (this.exists) {
			if (!this.completed_once && p && p.timeline_cache) {
				// render immediately from the cached timeline in the data
				this.completed_once = true;
				let cache = p.timeline_cache;
				this.simple_model = { state: cache.state, report: cache.report, nodes: cache.nodes, errors: cache.errors, dates: [], series_nodes: [], compute_time: 0 }
				console.log(`%c${this.constructor.name}${this.called_method()}() -> using model cache`, 'background: #222; color: #bada55; padding: 5px; border-radius: 5px;');
				this.requestUpdate('simple_model');
			}
			if (!this.updating_cache) {
				console.log(`%c${this.constructor.name} -> requesting modeling run`, 'background: #222; color: #bada55; padding: 5px; border-radius: 5px;');
				this.timeline_debounce({ ...this.person, timeline_cache: null }, this.projecting && this.projection ? this.projection.data : null, 'person prop change');
			} else {
				console.log(`%c${this.constructor.name} -> clearing cache requests`, 'background: #222; color: #bada55; padding: 5px; border-radius: 5px;');
				this.updating_cache = false;
			}
		}
		this.ping_page(p.id);
		p?.changes?.forEach(c => this.register_change(c));
		this.subscribe_changes(p.id, this.person.changes.reduce((newest, next) => new Date(next.timestamp) > newest ? new Date(next.timestamp) : newest, null));
	}
	get person() {
		return this._person;
	}

	ping_page(personid) {
		if (debug) console.log("PING", personid);
		const insert_active_users = gql`mutation insert_active_users($objects: [active_users_insert_input!]!){
        insert_active_users( objects: $objects) {
          affected_rows
        }
      }`

		client.mutate({
			mutation: insert_active_users,
			variables: { objects: [{ person_id: personid }] },
		}).then(data => {
		}).catch(error => formatQueryError(error));
	}


	seen_changes = new Set();
	change_dates = new Map();
	register_change(c) {
		if (!this.seen_changes.has(c.id)) {
			this.seen_changes.add(c.id);
			c.timestamp = new Date(c.timestamp);
			c.ts = Math.floor(c.timestamp.getTime() / 1000);
			c.u = c?.user && 'x-hasura-user-id' in c.user ? c.user['x-hasura-user-id'] : 'migration';
			let key = `${c.ts}::${c.u}::${c.table}::${c.action}`;
			this.change_dates.set(key, [...(this.change_dates.get(key) || []), c]);
		}
	}
	get changes() {
		const change_icons = {
			'INSERT': 'insert_drive_file',
			'UPDATE': 'edit',
			'DELETE': 'delete'
		}
		const change_verbs = {
			'INSERT': 'added',
			'UPDATE': 'modified',
			'DELETE': 'deleted'
		}
		const change_labels = {
			'address': 'an address',
			'address+': 'addresses',
			'phone': 'a phone number',
			'phone+': 'phone numbers',
			'contribution': 'a contribution record',
			'contribution+': 'contribution records',
			'employment': 'an employment record',
			'employment+': 'employment records',
			'benefit': 'a retirement',
			'benefit+': 'retirements',
			'benefit_payment': 'a benefit payment',
			'benefit_payment+': 'benefit payments',
			'person': 'the person record',
			'person+': 'records',
			'withdrawal': 'a withdrawal',
			'withdrawal+': 'withdrawals',
			'projection': 'a projection',
			'projection+': 'projections',
			'beneficiary': 'a beneficiary',
			'beneficiary+': 'beneficiaries',
		}
		const table_names = {
			'address': 'Address',
			'address+': 'Addresses',
			'phone': 'Phone',
			'email': 'E-mail',
			'email+': 'E-mails',
			'phone+': 'Phones',
			'contribution': 'Contribution',
			'contribution+': 'Contributions',
			'employment': 'Employment',
			'employment+': 'Employments',
			'benefit': 'Retirement',
			'benefit+': 'Retirements',
			'benefit_payment': 'Payment',
			'benefit_payment+': 'Payments',
			'person': 'Person',
			'person+': 'Persons',
			'person_note': 'Note',
			'person_note+': 'Notes',
			'withdrawal': 'Withdrawal',
			'withdrawal+': 'Withdrawals',
			'projection': 'Retirement Projection',
			'projection+': 'Ret. Projections',
			'beneficiary': 'Beneficiary',
			'beneficiary+': 'Beneficiaries',
		};
		return Array.from(this.change_dates.values()).map(v => ({
			ts: v[0].ts,
			id: v[0].id,
			date: dayjs(v[0].timestamp).format('YYYY-MM-DD'),
			reldate: dayjs(v[0].timestamp).fromNow(),
			//user: v[0].u !== 'migration' ? v[0].u.split('@')[0].toUpperCase() : 'SYSTEM MIGRATION',
			user: v[0].u !== 'migration' ? v[0].u.toLowerCase() : 'system migration',
			action: v[0].action,
			table: v[0].table,
			name: v[0].table in table_names ? table_names[v[0].table] : `${v[0].table.toUpperCase()}`,
			icon: table_icons[v[0].table],
			action_icon: change_icons[v[0].action],
			verb: v[0].table === 'person' && v[0].action === 'INSERT' ? 'created' : change_verbs[v[0].action],
			label: v[0].table in change_labels ? `${v.length > 1 ? `${v.length} ${change_labels[`${v[0].table}+`]}` : `${change_labels[v[0].table]}`}` : `[${v[0].table}]`,
			alt_label: v[0].table in table_names ? `${v.length > 1 ? `${v.length} ${table_names[`${v[0].table}+`]}` : `${table_names[v[0].table]}`}` : `[${v[0].table.toUpperCase()}]`,
			changes: v
		})).sort((a, b) => b.ts - a.ts);
	}

	subscribe_changes(personid, last_timestamp) {
		if (debug) console.log("subscribe with ts=", last_timestamp.toISOString());
		const person_change_sub = gql`subscription changes($personid: uuid!, $ts: timestamptz){
        changes(where: {_and: [{person_id: {_eq: $personid}}, {timestamp: {_gt: $ts}}]}) {
              id
              action
              table
              timestamp
              new
            }
      }`

		let sub = client.subscribe({
			query: person_change_sub,
			variables: { personid: personid, ts: last_timestamp },
		}).subscribe(
			{
				next: c => {
					let data = c.data.changes;
					data.forEach(d => this.register_change(d))
					let last = data.length > 0 ? new Date(data[0].timestamp) : last_timestamp;
					///  console.log('>> sub data', data.length, JSON.stringify(data[0]));
					if (last.getTime() !== last_timestamp.getTime()) {
						///      console.log("RESUBBING", last, last_timestamp);
						sub.unsubscribe();
						this.subscribe_changes(personid, last);
					}
				},
				error: async (e) => {
					if (e.extensions && e.extensions.code === 'start-failed') {
						sub.unsubscribe();
						console.error("subscription failed", e);
					}
					console.error("!! subscription ERROR");
					console.error(e, e.length, JSON.stringify(e));
				},
				complete: () => console.warn("|| subscription ending")
			});
		///console.log("SUB", sub)
	}

	contributionReport() {
		this.show_contrib_report = true;
	}
}

window.customElements.define("person-page", PersonPage)
export { PersonPage }



const projection_editor_styles = css`
        :host {
          display: flex;
          width: 100%;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: flex-start;

         --transform-transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);

        }

        .shadowed {
          box-shadow: var(--mdc-top-app-bar-fixed-box-shadow, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
        }

        .projection-bar {
          position: fixed;
          top: 64px;
          left: 0;

          box-sizing: border-box;
          width: 100vw;
          height: 80px;
          padding-top: 7px;
          padding-right: 24;
          padding-left: 24px;

          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;

          --transform-transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
          --mdc-theme-primary: var(--paper-teal-900);
          --mdc-theme-on-primary: white;
          --mdc-text-field-fill-color: white;
          overflow: visible;
          z-index: 2;
          background-color: var(--paper-teal-200);
          color: var(--paper-teal-900);
        }
        .projection-bar > * {
          margin-right: 12px;
        }
        .projection-bar > h2 {
          color: var(--paper-purple-900);
          opacity: 0;
          display: none;
        }
        .projection-bar > div, .projection-bar .subgroup {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
        }
        .subgroup {
          margin-left: 24px;
        }
        .subgroup > * {
          margin-right: 12px;
        }



        .projection-bar > div > * {
          margin-right: 12px;
        }

        kale-card {
          margin-top: 20px; 
          width: 100%;
        }

        kale-card > .card-content, kale-card > h3 {
          --mdc-theme-primary: var(--paper-teal-900);
          --mdc-theme-on-primary: white;
          --mdc-text-field-fill-color: white;
          background-color: var(--paper-teal-200);
          color: var(--paper-teal-900);
        }
        kale-card > h3 {
          text-transform: uppercase;
        }
        kale-card, kale-card > .card-content {
          overflow: visible;
          position: relative;
        }

        .bottom_buttons {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
        }

        .bottom_buttons > * {
          margin-left: 12px;
          margin-top: 24px;
        }

        .bottom_buttons > *:first-child {
          margin-right: auto;
        }

        div.row {
          position: relative;
          width: 100%;

          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
        }
        .bottom_buttons > * {
          margin-left: 12px;
          margin-top: 24px;
        }

        div.select-tainer {
          display: inline-block;
          height: 56px;
          width: 200px;
        }
        div.select-tainer > mwc-select {
          position: fixed;
        }
        div.select-tainer[opened] > mwc-select {
          z-index: 5;
        }

		div.select-tainer-expanded {
          display: inline-block;
          height: 56px;
          width: 300px;
		  margin-top: 12px;
        }
        div.select-tainer-expanded > mwc-select {
          position: fixed;
        }
        div.select-tainer-expanded[opened] > mwc-select {
          z-index: 10;
		  width: 500px;
        }

        mwc-textfield {
          margin: 0;
        }
        
        mwc-textfield[wide] {
          width: 100%;
        }


        `;

const sleep = async (ms) => new Promise(resolve => setTimeout(resolve, ms));
class ProjectionEditor extends KaleForm {
	static styles = [projection_editor_styles]

	static get properties() {
		return {
			tax_factor_filenames: {type: Array},
			active_tax_factors: {type: Number},
			long: { type: Boolean },
			end_open: { type: Boolean },
			ret_open: { type: Boolean },
			sal_open: { type: Boolean },
			show_report: { type: Boolean }
		};
	}

	set result(r) {
		console.warn("GOT A SIMULATION RESULT", r);
		this._result = r;
		this.requestUpdate('result');
	}
	get result() { return this._result }



	set name(n) {
		this.internal = {
			...this.projection,
			name: n
		}
	}
	get name() {
		return this.projection?.name
	}

	set end_condition(c) {
		console.log("end_cond -> ", c)
		this.internal = {
			...this.projection,
			assumptions: {
				...this.projection.assumptions,
				end_condition: c
			}
		}
		this.requestUpdate('end_condition');
	}
	get end_condition() {
		return this.projection?.assumptions?.end_condition;
	}

	set salary(s) {
		console.log("sal -> ", s)
		this.internal = {
			...this.projection,
			assumptions: {
				...this.projection.assumptions,
				salary: s
			}
		}
	}

	get salary() {
		return this.projection?.assumptions?.salary;
	}

	set beneficiary(b) {
		this.internal = {
			...this.projection,
			assumptions: {
				...this.projection.assumptions,
				beneficiary: b
			}
		}
	}

	get beneficiary() {
		return this.projection?.assumptions?.beneficiary;
	}

	set contributions_est(c) {
		console.log("ctrb -> ", c)
		this.internal = {
			...this.projection,
			assumptions: {
				...this.projection.assumptions,
				contributions_est: c
			}
		}
	}

	get contributions_est() {
		return this.projection?.assumptions?.contributions_est;
	}

	get fudges() {
		return this.projection?.assumptions?.calculation_fudges;
	}
	set fudges(f) {
		this.internal = {
			...this.projection,
			assumptions: {
				...this.projection.assumptions,
				calculation_fudges: f
			}
		}
	}

	seen_data = new WeakSet();

	get projection() {
		return this._projection;
	}

	get server_saved() {
		return this.projection.id && true;
	}

	set internal(p) {
		if (this._projection !== p && !this.seen_data.has(p)) {
			this.seen_data.add(p);
			this._projection = p;
			this.requestUpdate('projection');
		}
		this.dispatchEvent(new CustomEvent('changed', { detail: { ...this.projection, dirty: true } }));
	}

	set projection(p) {
		if (this._projection !== p && !this.seen_data.has(p)) {
			this.seen_data.add(p);
			this._projection = p;
			this._external = p;
			this.calculating = true;

			this.requestUpdate('projection');
			this.seen_data = new WeakSet();
			this.updateComplete.then(() => {
				this.renderRoot.querySelectorAll('mwc-select').forEach(s => s.layout());
				this.send_redirect();
			});
		}
		/*
		this.updateComplete.then(() => {
			this.renderRoot.querySelector('mwc-select').forEach(a => a.layout())
		})*/
	}


	get redirect() {
		if (!this._redirect) {
			this._redirect = new MutationRedirect(
				this,
				EditProjectionInfo,
				(t, data, obj, new_data) => {
					console.log("EXTRACTING DATA", data, obj);
					let changes = new_data?.changes;
					let id = obj?.id;
					if (!id) console.error("NO ID FOR", obj);

					let ret = changes?.[t]?.find(d => d.id === id);
					if (!ret) console.error("UNABLE TO FIND EXTRACT DATA");

					console.log("FOUND DATA", ret)

					return { ...obj, ...ret };
				},
				(t, data, obj) => {
					console.log("INSERTING DATA", data, obj)
					//let merged = {...obj, ...data};
					let id = obj?.id; //? obj?.id : this.createGuid();

					let changes = this.projection.changes;
					if (!changes?.[t]) {
						changes[t] = [{ ...data, id }];
					} else {
						changes[t] = [...(changes[t]).filter(c => c.id !== id), { ...data, id }];
					}
					console.log("INSERTED CHANGE ->", changes);

					/*
					if (id) {
						let prior = this.projection.changes[id]
					}*/
					return { changes };
				},
				() => this.projection
			);
		}
		return this._redirect;
	}

	save_impl(data) {
		let temp_id = this.projection?.temp_id;
		let mut = new EditProjectionInfo(
			p => this.projection = p,  // data update function
			{ changeMap: null },  //initial variables
			p => { // finalizing function
				this.saving = false;
				this.dispatchEvent(new CustomEvent('saved', { detail: { ...this.projection, temp_id } }));
				//this.dispatchEvent(new CustomEvent('close', { detail: this }));
				this.setToDefault();
			},
			(e, msgs) => { // error handler
				this.error = { data: this.projection, error: e, msgs: msgs };
				this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
			}, this
		);

		console.warn("SAVING PROJ", data);
		mut.save(data, this.projection, "saving projection");
	}

	send_redirect() {
		console.log(`%csending redirect`, prjout, this.redirect);
		this.dispatchEvent(new CustomEvent('mutation-redirect', { bubbles: true, composed: true, detail: this.redirect }));
		//this.dispatchEvent(new CustomEvent('projection-changed', { bubbles: true, composed: true, detail: this.projection }));
	}

	nav_next() {
		this.dispatchEvent(new CustomEvent('next', { bubbles: true, composed: true }));
	}
	nav_previous() {
		this.dispatchEvent(new CustomEvent('previous', { bubbles: true, composed: true }));
	}
	save_proj() {
		this.save();
	}
	delete_proj() {
		if (this.server_saved) {
			let mut = new EditProjectionInfo(
				p => this.projection = null,  // data update function
				{ id: this.projection?.id },  //initial variables
				p => { // finalizing function
					this.saving = false;
					console.log("DELETED", p);
					this.dispatchEvent(new CustomEvent('deleted', { detail: this.projection }));
				},
				(e, msgs) => { // error handler
					this.error = { data: this.projection, error: e, msgs: msgs };
					this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
				}, this
			);
			console.log("attempting delete", this.projection);
			mut.delete(this.projection);

		} else {
			console.log("deleting temporary", this.projection);
			this.dispatchEvent(new CustomEvent('deleted', { detail: this.projection }));
		}
	}
	new_proj() {
		this.dispatchEvent(new CustomEvent('add', { bubbles: true, composed: true }));
	}
	render() {
		return this.long ? this.renderLong() : this.renderShort();
	}

	firstUpdated() {
		let focused = this.renderRoot.activeElement;
		fetch_factor_filenames().then(r => this.tax_factor_filenames = r);
		sleep(500).then(() => {
			let fixes = this.renderRoot.querySelectorAll('mwc-select');
			console.log("FIXING FOCUS", fixes);
			fixes.forEach(f => f.focus());
			focused?.focus?.();
		});
	}

	//<mwc-textfield label="Ret. Type" .value=${this?.end_condition?.ret} @input=${e => this.end_condition = {...this.end_condition, ret: e.target.value}}></mwc-textfield>
	//<h3>Projection</h3>
	renderLong() {
		//this.factor_filenames = await this.factor_filenames;
		let tax_factor_filenames = this.tax_factor_filenames ?? [];
		return html`
    <kale-card elevation=1>
      <div class="card-content">
        <div>
          <mwc-textfield label="Name" wide .value=${this?.projection?.name} @input=${e => this.projection.name = e.target.value}></mwc-textfield>
        </div>

        <div class="row">
          <div class="select-tainer" ?opened=${this.end_open}>
            <mwc-select class="render_fix" label="End With" @opened=${_ => this.end_open = true} @closed=${_ => this.end_open = false}>
                <mwc-list-item @request-selected=${_ => this.end_condition = { ...this.end_condition, type: "years" }} ?selected=${this.end_condition?.type === 'years'} value="years">Time Elapsed </mwc-list-item>
                <mwc-list-item @request-selected=${_ => this.end_condition = { ...this.end_condition, type: "age" }} ?selected=${this.end_condition?.type === 'age'} value="age">Age</mwc-list-item>
                <mwc-list-item @request-selected=${_ => this.end_condition = { ...this.end_condition, type: "ret" }} ?selected=${this.end_condition?.type === 'ret'} value="retirement">Retirement Eligible</mwc-list-item>
                <mwc-list-item @request-selected=${_ => this.end_condition = { ...this.end_condition, type: "date" }} ?selected=${this.end_condition?.type === 'date'} value="date">Fixed Date</mwc-list-item>
            </mwc-select>
          </div>
          ${this.end_condition?.type === "years" ? html`<mwc-textfield label="Years" type="number" .value=${this?.end_condition?.years} @input=${e => this.end_condition = { ...this.end_condition, years: Number(e.target.value) }}></mwc-textfield>` : nothing}
          ${this.end_condition?.type === "age" ? html`<mwc-textfield label="Age" type="number" .value=${this?.end_condition?.age} @input=${e => this.end_condition = { ...this.end_condition, age: Number(e.target.value) }}></mwc-textfield>` : nothing}
          ${this.end_condition?.type === "ret" ? html`
            <div class="select-tainer" ?opened=${this.ret_open}>
              <mwc-select label="Type" class="render_fix" @opened=${_ => { this.ret_open = true; }} @closed=${_ => this.ret_open = false}>
                <mwc-list-item @request-selected=${_ => this.end_condition = { ...this.end_condition, ret: "full" }} value="full" ?selected=${!this.end_condition?.ret || this.end_condition?.ret === 'full'}>Full</mwc-list-item>
                  <mwc-list-item @request-selected=${_ => this.end_condition = { ...this.end_condition, ret: "unreduced" }} value="unreduced"  ?selected=${this.end_condition?.ret === 'unreduced'}>Unreduced</mwc-list-item>
                  <mwc-list-item @request-selected=${_ => this.end_condition = { ...this.end_condition, ret: "early" }} value="early"  ?selected=${this.end_condition?.ret === 'early'}>Early</mwc-list-item>
              </mwc-select>
            </div>
          ` : nothing}


          ${this.end_condition?.type === "date" ? html`<mwc-textfield label="Date" type="date" .value=${this?.end_condition?.date} @input=${e => this.end_condition = { ...this.end_condition, date: e.target.value }}></mwc-textfield>` : nothing}
        </div>
        <div class="ugly_salary_history">
          <div class="select-tainer" ?opened=${this.sal_open}>
            <mwc-select label="Final Salary Computation" class="render_fix" @opened=${_ => this.sal_open = true} @closed=${_ => this.sal_open = false}>
                <mwc-list-item @request-selected=${_ => this.salary = { ...this.salary, type: "history" }} ?selected=${this.salary?.type === 'history'} value="years">Actual History</mwc-list-item>
                <mwc-list-item @request-selected=${_ => this.salary = { ...this.salary, type: "pct" }} ?selected=${this.salary?.type === 'pct'} value="years">Yearly Increase</mwc-list-item>
                <mwc-list-item @request-selected=${_ => this.salary = { ...this.salary, type: "manual" }} ?selected=${this.salary?.type === 'manual'} value="retirement">Manual</mwc-list-item>
            </mwc-select>
            </div>
          ${this.salary?.type === "pct" ? html`<mwc-textfield label="Annual Raise %" type="number" .value=${this?.salary?.pct} @input=${e => this.salary = { ...this.salary, pct: Number(e.target.value) }}></mwc-textfield>` : nothing}
          ${this.salary?.type === "manual" ? html`<mwc-textfield label="Final Avg. Salary" type="number" .value=${this?.salary?.manual} @input=${e => this.salary = { ...this.salary, manual: Number(e.target.value) }}></mwc-textfield>` : nothing}
          ${this.salary?.type === "history" ? html`
          ${this.salary?.salaries?.map?.((s, i) => html`
          <div class="row">
          <mwc-textfield label="Salary" type="number" .value=${s?.salary_amt} @input=${e => this.salary = { ...this.salary, salaries: this.salary.salaries.with(i, { ...s, salary_amt: Number(e.target.value) }) }}>
            </mwc-textfield>
          <mwc-textfield label="Effective Date" type="date" min="1950-01-01" max="5000-01-01" .value=${s?.effective_date} @input=${e => this.salary = { ...this.salary, salaries: this.salary.salaries.with(i, { ...s, effective_date: e.target.value }) }}>
            </mwc-textfield>
            <mwc-icon-button icon= "close" class="remove-button" @click=${e => this.salary = { ...this.salary, salaries: this.salary.salaries.filter(sal => !(sal?.salary_amt === s.salary_amt && sal?.effective_date === s.effective_date)) }}></mwc-icon-button>

          </div>
          `)}

        ` : nothing}  <mwc-icon-button 
        icon="add" 
        class="add-button" 
        @click=${e => {		
				let default_salary = this.salary.salaries.at(-1) ?? {salary_amt:0, effective_date: new dayjs()}; //pick an item from contributions with map function (salary and eff_date) 
				this.salary = {
					...this.salary,
					salaries: [...this.salary.salaries, { salary_amt: default_salary.salary_amt, effective_date: new dayjs(default_salary.effective_date).add(-1, 'year').format('YYYY-MM-DD') }].sort((a,b) => b.effective_date-a.effective_date)
				}}}></mwc-icon-button>
               
        </div>
        <div class="row">
          <mwc-textfield label="Beneficiary DOB" type="date" .value=${this?.beneficiary?.dob ?? this.person} @input=${e => this.beneficiary = { ...this.beneficiary, dob: e.target.value }}></mwc-textfield>
        </div>
		<div class="select-tainer-expanded" ?opened=${this.end_open}>
            <mwc-select fullwidth class="render_fix" label="Factor filename" @opened=${_ => this.end_open = true} @closed=${_ => this.end_open = false}>
				${(tax_factor_filenames).map((fn,i) =>  html`<mwc-list-item ?selected=${this.active_tax_factors ? fn.tax_factors_id === this.active_tax_factors.id : i === 0} @request-selected=${e => {
					console.log("fxn call",e, fn);
					fetch_tax_factor_by_id(fn.tax_factors_id).then(factor_data => this.active_tax_factors = factor_data)
				}
				}> ${fn.tax_factors_date}, ${fn.tax_factors_filename} </mwc-list-item>`)}
            </mwc-select>
          </div>
        <div class="bottom_buttons">
          <mwc-icon-button icon="delete" raised @click=${e => this.delete_proj()}></mwc-icon-button>
          <mwc-button raised @click=${e => this.dispatchEvent(new CustomEvent('projection-report'))} icon="print">report</mwc-button>
          <mwc-button ?disabled=${!this._projection?.dirty && this._projection === this._external && this._projection?.id} icon="save" raised @click=${e => this.save_proj()}>save</mwc-button>
        </div>
      </div>
    </kale-card>


      `
	}   //salary.salaries => {salary, dt}
	//         ${this.salary?.salaries?.sort?.((a,b) => (new Date(a.effective_date)/1) - (new Date(b.effective_date)/1))?.map?.((s,i) => html`


	/* button for est contributions
				<div class="row">
					<mwc-textfield label="Estimated Contributions" type="number" .value=${this?.contributions_est?.est ?? this.person} @input=${e => this.contributions_est = {...this.contributions_est, est: Number(e.target.value)}}></mwc-textfield>
				</div>
				*/

	//${this.result ? this.renderEstimateChart(this.result?.ret_estimates?.target) : nothing}


	renderShort() {
		return html`
    <div style="height: 80px; z-index: -1;"></div>
    <div class="shadowed projection-bar">
      <div>
        <mwc-icon-button icon="navigate_before" @click=${e => this.nav_back()}></mwc-icon-button>
        <kale-textfield 
          label="Projection Name"
          .field=${'name'}
          .value=${this?.projection?.name}
          .default=${this?.projection?.name}
          ></kale-textfield>
          <kale-date
            label="Target Date"
            .field=${'target_date'}
            .value=${this?.projection?.target_date}
            .default=${this?.projection?.target_date || dayjs().add(5, 'year').format('YYYY-MM-DD')}
          ></kale-date>

        <mwc-icon-button icon="navigate_next" @click=${e => this.nav_next()}></mwc-icon-button>
      </div>

      <div>
        <mwc-button icon="save" raised @click=${e => this.save_proj()}>save</mwc-button>
        <mwc-button icon="delete" raised @click=${e => this.delete_proj()}>delete</mwc-button>
        <mwc-button icon="add_circle" raised @click=${e => this.new_proj()}>new</mwc-button>
      </div>
    </div>
      `
	}

}

/*
					<kale-textfield 
						label="Name"
						hundred
						field=${'name'}
						.value=${this?.projection?.name}
						.default=${this?.projection?.name}
						></kale-textfield>

				<div>
					<h4>Salary:
					<mwc-formfield label="Last">
						<mwc-radio name="salary_type"></mwc-radio>
					</mwc-formfield>
					<mwc-formfield label="Manual">
						<mwc-radio name="salary_type"></mwc-radio>
					</mwc-formfield>
					<mwc-formfield label="Annual Increase">
						<mwc-radio name="salary_type"></mwc-radio>
					</mwc-formfield></h4>
					<div class="subgroup">
						<kale-textfield type="number" outlined label="Final Salary" .value=${this?.projection?.name}></kale-textfield>
						<kale-textfield type="number" outlined label="% Annual Increase" .value=${this?.projection?.name}></kale-textfield>
					</div>
				</div>
				<div>
					<h4>End at:
					<mwc-formfield label="Years">
						<mwc-radio name="end_condition"></mwc-radio>
					</mwc-formfield>
					<mwc-formfield label="Age">
						<mwc-radio name="end_condition"></mwc-radio>
					</mwc-formfield>
					<mwc-formfield label="Date">
						<mwc-radio name="end_condition"></mwc-radio>
					</mwc-formfield>
					<mwc-formfield label="Ret. Eligible">
						<mwc-radio name="end_condition"></mwc-radio>
					</mwc-formfield></h4>
					<kale-date
						label="Target Date"
						.field=${'target_date'}
						.value=${this?.projection?.target_date}
						.default=${this?.projection?.target_date || dayjs().add(5, 'year')}
					></kale-date>
				</div>Breakdown

*/



/*        <div class="vgroup">

				<mwc-formfield label="no projection">
					<mwc-radio name="location"></mwc-radio>
				</mwc-formfield>

				<mwc-formfield label="manual">
					<mwc-radio name="location"></mwc-radio>
				</mwc-formfield>
				<mwc-formfield label="annual increase">
					<mwc-radio name="location"></mwc-radio>
				</mwc-formfield>

				</div>
				<div class="subgroup">
					<kale-textfield type="number" outlined label="Final Salary" .value=${this?.projection?.name}></kale-textfield>
					<div>or</div>
					<kale-textfield type="number" outlined label="% Annual Increase" .value=${this?.projection?.name}></kale-textfield>
				</div>
			</div>
			*/
window.customElements.define("projection-editor", ProjectionEditor);
export { ProjectionEditor }


const projection_report_styles = css`
        :host {
        ${colors}
          display: flex;
          width: 100%;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: flex-start;

         --transform-transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);

        }
        mwc-dialog {
         --mdc-dialog-max-width: calc(99vw - 100px);
        }

        @page {
            counter-reset: footnote 0;
        }
        
        @page {
            @footnote {
                border-top: dashed red 1px;
                float: bottom;
            }
        }
        span.prefooter {
          page-break-after: always;
        }
        span.footnote {
          display: none;
        }

        @media print {
          #print-mode {
						font-size: 10pt;
            max-width: 100vw;
            height: 100%;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
          }
        span.footnote {
          display: block; 
          break-inside: avoid;
          float: footnote;
          border-top: 1px solid #666;
          padding-top: 0.5em;
          font-style: italic;
          margin-top: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100vw;
          font-size: 70%;
          color: #333;
        }
          h1, h2, h3 {
            break-before: auto;
            break-after: avoid;
            font-family: "Mate";
            margin-bottom: 0;
          }
          #print-mode > h2 {
            font-size: 24pt;
            margin-top: 0;
            margin-bottom: 0.5em;
          }
          div.est_header_main {
          }

          div.report_header { 
            margin-left: 0 !important;
            margin-bottom: 0.5em !important;
            margin-bottom: 0.15in;
            border-bottom: 2px solid var(--paper-grey-300);
            padding-bottom: 0.25in;
          }
          div.est_totals {
            break-before: avoid;
            break-after: auto;
            margin-top: 1em !important;
            margin-top: 1em !important;
          }
          div.est_info_table {
            break-before: avoid;
            break-after: auto;
            break-inside: avoid;
            margin: 0 !important;
            width: 100vw !important;
            display: flex;
            flex-direction: column;
            flex: 1;
          }
          div.est_info_table table {
            margin: 0 !important;
            font-size: 8pt;
          }
          div.est_info_table table td:nth-of-type(1) {
            padding-right: 2em !important;
          }
          div.est_chart {
            break-after: auto;
            break-before: avoid;
            break-inside: avoid;
            max-width: 100vw !important;
            overflow: hidden !important;
          }
          table {
            break-inside: avoid;
            max-width: 100vw !important;
            width: 100vw !important;
            overflow-y: hidden !important;
          }
					div.est_chart td,th { border: none; padding: 1em !important;}
          div.est_chart td { white-space: nowrap; }
          td,th { border: none; padding: 0.5em !important;}
          th { position: initial !important; padding: 0 !important }
          p,li {
            margin: 0 !important;
            line-height: 1.4 !important;
          }
          ul { 
            margin: 0 !important;
            padding-inline-start: 1.4em !important;
          }
        }
        .table-container { position: relative; padding-top: 37px;}
        .table-scroller { overflow-y: overlay; max-height: 70vh; }
        table { border-collapse: collapse; width: 100%;}
        td,th { border: none; padding: 10px 28px;}
        th {
          text-transform: uppercase;
          border: none;
          white-space: nowrap;
          background-color: white;
          z-index: 1;
        }
        
        tr { 
          border: none; 
          break-inside: avoid;
        }
        tr:nth-child(even) { background: #CCC;}

        td.remove { padding: 10px 0px;}
        td.date { 
        }

        td.money, th.money{
          text-align: right;
        }

        td.parenthetical {
          font-weight: 100;
          font-size: 90%;
          font-style: italic;
          opacity: 0.95;
        }

        span.currency {
          float: left;
        }
        contrib-datecell[changed], contrib-amtcell[changed] {
        font-style: italic;
        color: var(--paper-green-600); 
        }

        th span {
        }

        span.header_item {
          display: block;
        }

        div.header_row {
          display: flex;
          flex-direction: row;
        }

        span.header_name {
          /*grid-column: name;*/
          font-weight: 100;
          margin-right: 1em;
          flex: 1;
          font-variant: all-small-caps;
        }
        span.header_name::after {
          content: "";
          border-bottom: 1px solid var(--paper-grey-200);
          display: inline-block;
          flex: 1;
          position: relative;
          bottom: 0.5em;
          left: 3px;
        }

        span.header_data {
          /*grid-column: data;*/
          font-weight: 900;
          /*float: right;*/
        }

        div.report_header {
          /*
          display: grid;
          grid-template-columns: [name] 1fr [data] 1fr;
          grid-template-rows: auto;
          margin-bottom: 2em;
          margin-left: 2em;
          width: fit-content;
          gap: 12px;
          column-gap: 1in;
          */
          gap: 1in;
          column-count: 2;
          margin-bottom: 24px;
          padding-bottom: 12px;
          border-bottom: 2px solid var(--paper-grey-300);
        }

        .est_chart table {
          margin-left: auto;
          margin-right: auto;
        }

        /*
        .est_chart th:before {
          display: block;
          content: "monthly";
          font-variant: all-small-caps;
          font-weight: 100;
        }
        */

        th.fill:before {
          content: ""
        }

        tr.est_table_header {
          background-color: var(--paper-grey-800);
          color: white;
          border-top: 6px solid;
        }
        tr.est_table_header[sub_estimate] {
          background-color: var(--paper-grey-600);
        }

        .est_header_main {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .est_header_main > * {
          font-weight: 100;
          font-size: 90%;
        }


        .est_header_head {
          font-weight: 900;
          font-size: 110%;
        }

        tr.member td:first-child {
          padding-left: 1em;
        }

        tr.withdrawn {
          background-color: rgba(244, 143, 177, 0.2);

        }
        tr.in_plan {
          background-color: rgba(128, 222, 234, 0.2);
        }
        tr.pcts td:first-child {
          font-variant: all-small-caps;
          font-weight: 100;
          text-align: left;
        }
        tr.pcts td {
          font-weight: 100;
          text-align: center;
        }




        td[footnote], td[na] {
          font-weight: 100;
          font-style: italic;
          opacity: 0.65;
          text-align: center;
        }

        td[data] {
          text-align: center;
        }

        div.est_totals_row {
          display: flex;
          flex-direction: row;
        }

        div.est_totals {
          display: grid;
          grid-template-columns: [first] 1fr [second] 1fr [third] 1fr;
          grid-template-rows: auto;
          width: fit-content;
          margin: 24px;
          margin-left: auto;
          margin-right: auto;
          grid-gap: 20px;
        }

        div.totals_item {

        }
        div.totals_item.col1 { grid-column: first; }
        div.totals_item.col2 { grid-column: second; }
        div.totals_item.col3 { grid-column: third; }

        .totals_label, .totals_amount { display: block }

        .totals_label { 
          font-variant: all-small-caps;
          font-weight: 100;
        }

        .est_info_table table {
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 16px;
          margin-bottom: 16px;
        }
        .est_info_table  {
          font-size: 90%;
        }
        .est_info_table  {
          margin-left: 24px;
          margin-right: 24px;
        }

        .est_info_table tr:nth-child(2n+1) {
          background-color: var(--paper-grey-100);
        }

        .est_info_table tr[head] {
          font-variant: all-small-caps;
          font-weight: 100;
          background-color: transparent;
          break-after: avoid;
          break-before: auto;
        }
        .est_info_table tr[spacer], tr[spacer] td  {
          background-color: transparent;
          break-before: auto;
          break-after: auto;
          break-inside: auto;
          padding: 0 !important;
          /*height: 24px;*/
        }

        col {
          border-right: 3px solid var(--paper-grey-100);
        }

        /*
        td[head] {
          position: absolute;
        }
        .row_header {
          transform: rotate(-90deg);
          ￼  transform: rotate(-90deg);
  background-color: pink;
  position: relative;
  left: -3em;
  top: 3em;
  white-space: nowrap;
        }
        */

        div.content {
          max-width: 900px;
        }


        `;

const MONEY = (amt) => amt !== null && amt !== undefined && typeof (amt) === 'number' ? `${amt.toLocaleString([], { style: 'currency', currency: 'USD' })}` : '$ERR';
const MONEY_MO = (amt) => `${MONEY(amt)}`
const PCT = (amt, res) => amt !== null && amt !== undefined && typeof (amt) === 'number' ? amt.toLocaleString([], { style: 'percent', maximumFractionDigits: res || 0 }) : '$ERR';
const KMONEY = (amt) => amt !== null && amt !== undefined && typeof (amt) === 'number' ? `${(Math.round(amt / 1000)).toLocaleString([], { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}K` : '$UNKNOWN';

class ProjectionReport extends LitElement {
	static styles = [projection_report_styles]
	static properties = {
		projection: { type: Object },
		person: { type: Object },
		open: { type: Boolean },
		render_for_printing: { type: Boolean }
	}
	firstUpdated() {
		console.warn("PROJ REP", this.person, this.projection);
		this.installPrintHandler();
	}
	get name() {
		const { first_name, middle_name, last_name, suffix } = this.person;
		return [first_name, middle_name, last_name, suffix ? suffix.name : null].filter(x => x && x != "").join(" ")
	}
	async printReport() {
		this.setupPrinting();
		await this.updateComplete;
		print();
	}
	setupPrinting() {
		console.log("Printing Setup");
		this.render_for_printing = true;
		let instances = document.querySelectorAll('projection-report');
		instances.forEach(i => document.body.removeChild(i));
		document.body.appendChild(this);
		console.warn("print instances", instances);
	}
	breakdownPrinting() {
		console.warn("Printing Breakdown");
		this.render_for_printing = false;
		let instances = document.querySelectorAll('projection-report');
		console.log("instances", instances);
		document.body.removeChild(this);
		console.log("tear down printing");
	}
	installPrintHandler() {
		console.log("Installing");
		window.addEventListener("beforeprint", this.setupPrinting.bind(this));
		window.addEventListener("afterprint", this.breakdownPrinting.bind(this));
	}
	removePrintHandler() {
		console.log("Removing");
		window.removeEventListener("beforeprint", this.setupPrinting.bind(this));
		window.removeEventListener("afterprint", this.breakdownPrinting.bind(this));
	}
	render() {
		if (this.render_for_printing) return html`
    <div id="print-mode">
    <h2>${this.name}</h2>    
    ${this.projection ? this.renderEstimateHeader(this.person, this.projection) : this.renderEstimateErrorMessage(this.person)}
    ${this.projection ? this.renderEstimateChart(this?.projection?.ret_estimates?.target, false) : nothing}
	${this.projection ? this.renderEstimateTable(this?.projection?.ret_estimates?.target) : nothing}
    ${this.projection ? this.renderEstimateFooter(this?.projection?.ret_estimates?.target) : nothing}
    </div>`
	console.warn(this.person, this.projection);
	
		return html`
     <mwc-dialog id="projection_report_dialog"
     ?open=${true}
      @closed=${_ => {
				this.dispatchEvent(new CustomEvent('closed', { bubbles: true, composed: true, detail: {} }));
				this.removePrintHandler();
			}
			}
     >
          <div class="header"> <h2>${this.name}</h2></div>
          <mwc-button slot="secondaryAction" icon="print" @click=${e => this.printReport()}>print</mwc-button>
          <mwc-button slot="primaryAction" @click=${_ => this.renderRoot.getElementById('projection_report_dialog').close()}>close</mwc-button>
        </div>
        <div class="content">
          ${this.projection ? this.renderEstimateHeader(this.person, this.projection) : this.renderEstimateErrorMessage(this.person)}
          ${this.projection ? this.renderEstimateChart(this?.projection?.ret_estimates?.target, false) : nothing}
		  ${this.projection ? this.renderEstimateTable(this?.projection?.ret_estimates?.target) : nothing}
        </div>
    </mwc-dialog>
    `
	}

	/*
					${this.projection && this.projection?.ret_estimates?.target?.sub_estimates?.length > 1 ? 
					this?.projection?.ret_estimates?.target?.sub_estimates?.map(e => html`
						${this.renderEstimateChart(e, false)}
					`) : nothing}
	*/
	renderEstimateHeader(p, { d, credited_years, ptd_credited_years, basis, participating, terminated, ret_estimates }) {
		const { d: est_target, spans, avg_salary, service, contribs, ages: { ee: { dob: ee_dob }, beneficiary: { dob: bf_dob } } } = ret_estimates?.target;
		return html`
    <div class="report_header">
      <div class="header_row">
        <span class="header_name">Estimate as of</span><span class="header_data">${dayjs(est_target).format('M/DD/YYYY')}</span>
      </div>
      <div class="header_row">
        <span class="header_name">Date of Birth</span><span class="header_data">${dayjs(ee_dob).format('M/DD/YYYY')}</span>
      </div>
      <div class="header_row">
        <span class="header_name">Est. Average Annual Salary</span><span class="header_data">${MONEY(avg_salary)}</span>
      </div>
      <div class="header_row">
        <span class="header_name">Est. Contributions & Interest </span><span class="header_data">${MONEY(contribs)}</span>
      </div>
      <div class="header_row">
        <span class="header_name">Service </span><span class="header_data">
        ${spans.map((s, i) => html`
        ${dayjs(s.start).format('M/DD/YYYY')}&ndash;${s.end ? dayjs(s.end).format('M/DD/YYYY') : ''}
        ${i < spans.length - 1 ? html`, ` : ''}
        `)}
        </span>
      </div>
      <div class="header_row">
        <span class="header_name">Total Credited Service</span><span class="header_data">${(service).toLocaleString()} years</span>
      </div>
      <div class="header_row">
        <span class="header_name">Beneficiary DOB</span><span class="header_data">${dayjs(bf_dob).format('M/DD/YYYY')}</span>
      </div>
    </div>
    `
		//${dayjs(basis).format('M/DD/YYYY')} &ndash; ${dayjs(terminated ?? est_target).format('M/DD/YYYY')}
		//this.title = this.person && this.exists ? [p.first_name, p.middle_name, p.last_name, p.suffix ? p.suffix.name : null].filter(x => x && x != "").join(" ") : this.exists ? "LOADING" : "NO SUCH PERSON";
		/*
		dayjs().add(5, 'year').format('YYYY-MM-DD'),
		${this.name}</span>
participant name
Estimate as of April 1, 2021
Date of Birth 10/13/1958
Estimated Average Annual Salary = 122,997.00
Estimated Contributions & Interest = $110,123.31
 Service 02/20/2001-03/31/2021
Total Credited Service = 20.19 years
Beneficiary date of 03/25/1965


			<div class="totals_row">
				<div class="totals_item"><span class="totals_label">Nontaxable</span><span class="totals_amount">${MONEY(nontaxable)}</span></div>
				<div class="totals_item"><span class="totals_label">Taxable</span><span class="totals_amount">${MONEY(taxable)}</span></div>
				<div class="totals_item"><span class="totals_label">Total</span><span class="totals_amount">${MONEY(nontaxable+taxable)}</span></div>
			</div>
*/
	}
	renderEstimateErrorMessage(p) {
		/*
		This method is meant to salvage any useful information from the person json and present it in a report format so the 
		report is not completely empty. Right now most of the information will show up as an error
		TODO: You can get the contributions in the Est. Contributions and Interst section but right now this returns an error
		*/

		return html`
		<div class="report_header">
		  <div class="header_row">
        	<span class="header_name">Estimate as of</span><span class="header_data">${dayjs('error').format('M/DD/YYYY')}</span>
      	  </div>	
		  <div class="header_row">
			<span class="header_name">Date of Birth</span><span class="header_data">${dayjs(p.birth_date).format('M/DD/YYYY')}</span>
		  </div>
		  <div class="header_row">
        	<span class="header_name">Est. Average Annual Salary</span><span class="header_data">${MONEY('error')}</span>
      	  </div>
		  <div class="header_row">
			<span class="header_name">Est. Contributions & Interest </span><span class="header_data">${MONEY(p.contributions)}</span>
		  </div>
      <div class="header_row">
        <span class="header_name">Total Credited Service</span><span class="header_data">${("error").toLocaleString()} years</span>
      </div>
      <div class="header_row">
        <span class="header_name">Beneficiary DOB</span><span class="header_data">${dayjs("error").format('M/DD/YYYY')}</span>
      </div>
		</div>
		`
		
	}
	renderEstimateTable({ total_contribs: contributions, total_interest: interest, taxable, nontaxable, ...rest }) {
		// renders the contributions, interest, and tax information 
		//console.warn("EST FOOTER", rest);
		//const nontaxable = (contributions+interest)/2;
		//const taxable = nontaxable;

		return html`
    <div class="est_totals_row">
      <div class="est_totals">
          <div class="totals_item col1"><span class="totals_label">Contributions</span><span class="totals_amount">${MONEY(contributions)}</span></div>
          <div class="totals_item col2"><span class="totals_label">Interest</span><span class="totals_amount">${MONEY(interest)}</span></div>
          <div class="totals_item col3"><span class="totals_label">Total</span><span class="totals_amount">${MONEY(contributions + interest)}</span></div>
      </div>
      <div class="est_totals">
          <div class="totals_item col1"><span class="totals_label">Nontaxable</span><span class="totals_amount">${MONEY(nontaxable)}</span></div>
          <div class="totals_item col2"><span class="totals_label">Taxable</span><span class="totals_amount">${MONEY(taxable)}</span></div>
          <div class="totals_item col3"><span class="totals_label">Total</span><span class="totals_amount">${MONEY(nontaxable + taxable)}</span></div>
      </div>
    </div>`
	}

	renderEstimateFooter({ total_contribs: contributions, total_interest: interest, taxable, nontaxable, ...rest }) {
		return html`
    <div class="est_info_table">
    <h3>Benefit Options</h3>

      <table>
        <tbody>
        <tr head>
          <td head><div class="row_header">automatic forms</div></td>
        </tr>
          <tr>
            <td>Single Life Annuity</td>
            <td>
              <ul class="benefit_desc">
                <li>Standard payment option for a single employee.</li>
                <li>Benefits stop when you die.</li>
                <li>If you're married, spousal consent is required if you select this payment option.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Joint and 50% Survivor Annuity</td>
            <td>
              <ul class="benefit_desc">
                <li>Standard payment option for a married employee.</li>
                <li>Pays a lower benefit than a single life annuity, but 50% of your benefit will continue to your spouse or beneficiary after you die.&ast;</li>
              </ul>
            </td>
          </tr>
          <tr spacer><td colspan="2"></td></tr>
        <tr head>
          <td head><div class="row_header">optional forms</div></td>
        </tr>
          <tr>
            <td>Joint 50%, 75% or 100% Survivor Annuity</td>
            <td>
              <ul class="benefit_desc">
                <li>These options pay lower benefits than a single life annuity, but 50%, 75% or 100% of your benefit will continue to your spouse or beneficiary after you die.  </li>
                <li>Spousal consent is required if you designate anyone other than your spouse as your beneficiary. These options may not be available if the age difference between you and your spouse or beneficiary is too great.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>10-Year Certain and Life Annuity</td>
            <td>
              <ul class="benefit_desc">
              <li>Benefits continue over your lifetime.</li>
              <li>If you die within the first 10 years, benefits continue to your spouse or beneficiary
              until 10 years from the annuity starting date.</li>
              <li>Spousal consent is required to select this payment option.</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <span class="prefooter"></span>
      <span class="footnote">
      &ast; If you have not been married for one year when your benefits start and you die in pay status before you have been married for
one year, no death benefit is payable to your spouse. If you have not been married for one year when your benefits start, and
your spouse dies while you are in pay status before you have been married for one year, your benefit will then revert to the single
life annuity form
  </span>
    `;

	}

	renderEstimateChart(est, short = true) {
		return html`
      <div class="est_chart">
        <table>
          <colgroup>
          <col/>
          <col/>
          <col/>
          <col/>
          <col/>
          </colgroup>
          <thead>
            <th class="fill"></th>
            <th>Straight<br/>Life</th>
            <th>50% J&S</th>
            <th>75% J&S</th>
            <th>100% J&S</th>
            <th>10 Year<br/>Certain</th>
          </thead>
          <tbody>
            <tr class="pcts">
              <td>% of straight life annuity</td>
              <td>100%</td>
              <td>${PCT(est.js50 / est.sla)}</td>
              <td>${PCT(est.js75 / est.sla)}</td>
              <td>${PCT(est.js100 / est.sla)}</td>
              <td>${PCT(est.certain10 / est.sla)}</td>
            </tr>

            ${(est.sub_estimates.length > 1 ? [...est.sub_estimates.map((e, i) => ({ ...e, sub_estimate: i + 1 })), est] : [est]).map(
			({ withdrawn,
				clamped,
				early,
				early_factor,
				sla, js50, js75, js100, certain10,
				wsla, wjs50, wjs75, wjs100, wcertain10,
				withdrawal_amt,
				avg_salary,
				service,
				withdrawal_factor,
				sub_estimate,
				period_start, period_end
			}) => html`

                <tr class="est_table_header" ?sub_estimate=${sub_estimate}>
                  <td colspan="6">
                  <div class="est_header_main">
                    <div class="est_header_title">
                      <div class="est_header_head">${sub_estimate ? `${ordinal(sub_estimate)} period of service` : "TOTAL"}</div>
                      <div class="est_header_subhead">${sub_estimate ? html`${dayjs(period_start).format('M/DD/YYYY')}&mdash;${dayjs(period_end).format('M/DD/YYYY')}` : html`Service ${service?.toLocaleString()} years`}</div>
                    </div>
                    <div class="est_header_info">
                    ${sub_estimate ? html`
                      <div class="est_header_item">
                        Est. Avg. Annual Salary: ${MONEY_MO(avg_salary)}
                      </div>
                      <div class="est_header_item">
                        Est. Contributions + Interest: ${MONEY_MO(withdrawal_amt)}
                      </div>
                      ` : nothing
				}
                    </div>
                  </div>
                  
                  </td>

                </tr>
              
                ${!withdrawn ? html`
                <tr class="in_plan member" ?sub_estimate=${sub_estimate}>
                  <td>${short ? 'ctrbs in plan' : 'If your contributions remain in plan'}</td>
                  <td data>${MONEY_MO(sla)}</td>
                  <td data>${MONEY_MO(js50)}</td>
                  <td data>${MONEY_MO(js75)}</td>
                  <td data>${MONEY_MO(js100)}</td>
                  <td data>${MONEY_MO(certain10)}</td>
                </tr>
                <tr class="in_plan survivor" ?sub_estimate=${sub_estimate}>
                  <td>${short ? 'survivor' : html`&hellip;paid to surviving spouse or beneficiary`}</td>
                  <td na>n/a</td>
                  <td data>${MONEY_MO(js50 * 0.5)}</td>
                  <td data>${MONEY_MO(js75 * 0.75)}</td>
                  <td data>${MONEY_MO(js100 * 1)}</td>
                  <td footnote>${short ? '*' : 'see below'}</td>
                </tr>
                ` : nothing}
                <tr class="withdrawn member" ?sub_estimate=${sub_estimate}>
                  <td>${short ? 'ctrbs wdrawn' : 'If your contributions are withdrawn'}</td>
                  <td data>${MONEY_MO(wsla)}</td>
                  <td data>${MONEY_MO(wjs50)}</td>
                  <td data>${MONEY_MO(wjs75)}</td>
                  <td data>${MONEY_MO(wjs100)}</td>
                  <td data>${MONEY_MO(wcertain10)}</td>
                </tr>
                <tr class="withdrawn survivor" ?sub_estimate=${sub_estimate}>
                  <td>${short ? 'survivor' : html`&hellip;paid to surviving spouse or beneficiary`}</td>
                  <td na>n/a</td>
                  <td data>${MONEY_MO(wjs50 * 0.5)}</td>
                  <td data>${MONEY_MO(wjs75 * 0.75)}</td>
                  <td data>${MONEY_MO(wjs100 * 1)}</td>
                  <td footnote>${short ? '*' : 'see below'}</td>
                </tr>
              
              `
		)}

          </tbody>
        </table>
      </div>
    
    
    `

	}
}
window.customElements.define("projection-report", ProjectionReport)
export { ProjectionReport }

const contrib_report_styles = css`
        :host {
          display: flex;
          width: 100%;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: flex-start;

         --transform-transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);

        }
        mwc-dialog {
         --mdc-dialog-max-width: calc(99vw - 100px);
        }

        .table-container { position: relative; padding-top: 37px;}
        .table-scroller { overflow-y: overlay ; max-height: 70vh; }
        table { border-collapse: collapse; width: 100%;}
        td,th { border: none; padding: 10px 28px;}
        th {
          text-transform: uppercase;
          border: none;
          white-space: nowrap;
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 1;
        }
        
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}

        td.remove { padding: 10px 0px;}
        td.date { 
        }

        td.money, th.money{
          text-align: right;
        }

        td.parenthetical {
          font-weight: 100;
          font-size: 90%;
          font-style: italic;
          opacity: 0.95;
        }

        span.currency {
          float: left;
        }
        contrib-datecell[changed], contrib-amtcell[changed] {
        font-style: italic;
        color: var(--paper-green-600); 
        }

        th span {
        }

        `;



const cell_formats = {
	'date': v => v?.toLocaleDateString('default') || '',
	//'date': v => v?.toLocaleString('default', { }) || '',
	'money': v => v?.toLocaleString([], { style: 'currency', currency: 'USD' }) || ''
}

class ContributionReport extends LitElement {
	static styles = [contrib_report_styles]

	constructor() {
		super();
	}

	firstUpdated() {
		console.log("CONTRIB RPT", this.person, this.model);
	}

	static get properties() {
		return {
			person: { type: Object },
			model: { type: Object }
		};
	}
	downloadReport() {
		let fields = this.column_names.map(f => f.long);
		let data = this.data.map(d => this.column_names.map(({ short, type }) => xlsx_formatters[type](d[short], { c: short, t: type }, d)));
		console.warn(`${this.person.first_name} ${this.person.last_name}`, this.person);
		let file = `Contribution_history-${this.person.legacy_pension_id}-${this.person.first_name}_${this.person.last_name}`
		let sheet = `${this.person.first_name} ${this.person.last_name}`
		this._saveXLSX(sheet, file, fields, data);

	}
	_saveXLSX(sheet, file, fields, data) {
		file = file.replace(/[\\\/?*\][]/g, '-');
		let all_data = [[...fields], ...data];
		const fitToColumn = (arrayOfArray) => arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] !== null && a2[i] !== undefined ? (typeof (a2[i]) === 'object' && a2[i].constructor.name === 'Date' ? a2[i].toLocaleDateString() : a2[i].toString()).length : 0)) }));
		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.aoa_to_sheet(all_data);

		let d2 = XLSX.utils.sheet_to_json(ws, { header: 1 });

		console.log("data", data);
		console.log("data", d2);

		ws['!cols'] = fitToColumn(d2);
		/* Add the worksheet to the workbook */
		XLSX.utils.book_append_sheet(wb, ws, sheet);
		console.log("XLS WB", wb);
		XLSX.writeFile(wb, file + ".xlsx");
	}


	get data() {
		let state = this?.model?.state;
		return state?.contribs.map(c => ({
			'date': c.date,
			'interest': c.interest,
			'amount': c.amt,
			'balance': c.total_interest + c.total_contribs,
			//'total_interest': state?.contribution_interest,
			//'total_contribution': state?.contribution_balance - state?.contribution_interest
		})) || [];
	}

	get column_names() {
		return [
			{ short: 'date', long: 'Date', type: 'date' },
			{ short: 'interest', long: 'Interest', type: 'money' },
			{ short: 'amount', long: 'Contribution', type: 'money' },
			{ short: 'balance', long: 'Balance', type: 'money' },
			//{short: 'total_interest', long: 'Total Interest', type: 'money'},
			//{short: 'total_contribution', long: 'Total Contribution', type: 'money'}
			/*
			"ContributionDate": d.contributiondate,
			"Interest": d.interest,
			"ContributionAmount": d.contributionamount,
			"AccountBalance": d.accountbalance,
			"TotalInterest": d.totalinterest,
			"TotalContribution": d.totalcontribution,


			(contrib.value -> 'type'::text) AS contribs_type,
			((contrib.value ->> 'contribution_date'::text))::date AS contribs_date,
			COALESCE(((contrib.value ->> 'contribution_date'::text))::date, ( SELECT c.contribution_date
						FROM contribution c
						WHERE ((c.person_id = cs.person_id) AND (date_part('year'::text, ((contrib.value ->> 'date'::text))::timestamp with time zone) = date_part('year'::text, c.contribution_date)) AND (((contrib.value -> 'amt'::text))::numeric = c.ee_pension_contribution))
					LIMIT 1), ((contrib.value ->> 'date'::text))::date) AS contributiondate,
			((contrib.value -> 'interest'::text))::numeric AS interest,
			((contrib.value -> 'amt'::text))::numeric AS contributionamount,
			(((contrib.value -> 'total_interest'::text))::numeric + ((contrib.value -> 'total_contribs'::text))::numeric) AS accountbalance,
			cs.contribution_interest AS totalinterest,
			(cs.contribution_balance - cs.contribution_interest) AS totalcontribution
*/


		]
	}

	;
	render() {
		return html`
        <mwc-dialog id="report_dialog" heading="Contributions" .open=${true} @closed=${e => this.dispatchEvent(new CustomEvent('closed', { bubbles: true, composed: true, detail: {} }))}>
          <div class="table-container">
            <div class="table-scroller">
              <table>
                <thead>
                  <tr>
                    ${this.column_names.map(c => html`
                      <th>${c.long}</th>
                    `)}
                  </tr>
                </thead>

                <tbody>
                  ${this.data.map(d => html`
                  <tr>
                    ${this.column_names.map(c => html`
                      <td>${cell_formats[c.type](d[c.short])}</td>
                    `)}
                  </tr>
                  `)}
                </tbody>

              </table>
            </div>
          </div>
          <mwc-button slot="secondaryAction" icon="download" @click=${e => this.downloadReport()}>download</mwc-button>
          <mwc-button slot="primaryAction" @click=${e => this.renderRoot.getElementById('report_dialog').close()}>close</mwc-button>
        </mwc-dialog> 
        `;

	}

}
window.customElements.define("contribution-report", ContributionReport)
export { ContributionReport }

/*
								${this.series_data ? this.series_data.map(d => html`
									<tr>
										${this.render_row(d)}
									</tr>
								`) : html``}

(contrib.value -> 'type'::text) AS contribs_type,
		((contrib.value ->> 'contribution_date'::text))::date AS contribs_date,
		COALESCE(((contrib.value ->> 'contribution_date'::text))::date, ( SELECT c.contribution_date
					 FROM contribution c
					WHERE ((c.person_id = cs.person_id) AND (date_part('year'::text, ((contrib.value ->> 'date'::text))::timestamp with time zone) = date_part('year'::text, c.contribution_date)) AND (((contrib.value -> 'amt'::text))::numeric = c.ee_pension_contribution))
				 LIMIT 1), ((contrib.value ->> 'date'::text))::date) AS contributiondate,
		((contrib.value -> 'interest'::text))::numeric AS interest,
		((contrib.value -> 'amt'::text))::numeric AS contributionamount,
		((contrib.value -> 'balance'::text))::numeric AS contribs_bal,
		((contrib.value -> 'total_interest'::text))::numeric AS contribs_total_interest,
		((contrib.value -> 'total_contribs'::text))::numeric AS contribs_total_contribs,
		(((contrib.value -> 'total_interest'::text))::numeric + ((contrib.value -> 'total_contribs'::text))::numeric) AS accountbalance,
		cs.contribution_interest AS totalinterest,
		(cs.contribution_balance - cs.contribution_interest) AS totalcontribution



	last_name
	first_name
	participantfullname
	birthdate
	hireddate
	benefitstartdate
	contributionstartdate
	pensionparticipantno
	activegroupname
	address
	address2
	city
	state
	zipcode
	contribs_type
	contribs_date
	contributiondate
	interest
	contributionamount
	contribs_bal
	contribs_total_interest
	contribs_total_contribs
	accountbalance
	totalinterest
	totalcontribution
	ssn
*/